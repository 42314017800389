<template>
  <content-with-sidebar class="blog-wrapper">

    <!-- Страница с новостями -->
    <b-overlay :show="dataLoading" rounded="sm">
      <b-row class="blog-list-wrapper">

        <!-- Карточки новостей -->
        <b-col
            v-for="blog in blogList"
            :key="blog.id"
            md="6"
        >

          <!-- Карточка -->
          <b-card
              tag="article"
              no-body
              class="d-flex"
          >

            <div class="article">
              <b-link :to="{ name: 'post-page', params: { slug: blog.slug, id:blog.id } }">
                <b-img
                    :src="blog.picture"
                    class="card-img-top"
                    v-bind="imgProp"
                    fluid
                />
              </b-link>
              <b-card-body>

                <!-- Заголовок  -->
                <b-card-title>
                  <b-link
                      :to="{ name: 'post-page', params: { slug: blog.slug, id:blog.id } }"
                      class="blog-title-truncate text-body-heading"
                  >
                    {{ blog.title }}
                  </b-link>
                </b-card-title>

                <!-- Автор  -->
                <b-media no-body>

                  <!-- Автар  -->
                  <b-media-aside
                      vertical-align="center"
                      class="mr-50 d-flex"
                  >
                    <b-avatar
                        size="2rem"
                        :src="blog.author.avatar"
                    />
                  </b-media-aside>

                  <!-- Имя -->
                  <b-media-body class="">
                      <b-link class="text-body">{{getFullname(blog.author) }}</b-link>
                      <small class="text-muted">
                        |
                        <span>{{ blog.created_at | moment("DD.MM.YYYY") }}</span>
                      </small>
                  </b-media-body>
                </b-media>

                <!-- Категории  -->
                <div class="my-1 py-25">
                  <b-link
                      v-for="category in blog.categories"
                      :key="category.id"
                  >
                    <b-badge
                        pill
                        class="mr-75"
                        variant="light-primary"
                    >
                      {{ category.title }}
                    </b-badge>
                  </b-link>
                </div>

                <!-- Краткий текст -->
                <b-card-text class="blog-content-truncate">
                  <span v-html="blog.text"></span>
                </b-card-text>
              </b-card-body>
            </div>
            <b-card-body class="card-buttons">
              <hr>

              <!-- Кнопки  -->
              <div class="d-flex justify-content-between align-items-center">
                <b-link :to="{ name: 'post-page', params: { slug: blog.slug, id:blog.id } }">
                  <div class="d-flex align-items-center text-body">
                    <feather-icon
                        icon="MessageSquareIcon"
                        class="mr-50"
                    />
                    <span class="font-weight-bold">Комментариев: {{ blog.commentsLength }} </span>
                  </div>
                </b-link>
                <b-link
                    :to="{ name: 'post-page', params: { slug: blog.slug, id:blog.id } }"
                    class="font-weight-bold"
                >
                  Подробнее
                </b-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>

    <!-- СайдБар  -->
    <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
    >

      <!-- Новая статья -->
      <b-button v-if="$store.state.app.user && $store.state.app.user.account_type === 'ADMIN'" variant="primary" class="col-12 mb-2" :to="{ name: 'post-new'}">+ Новая статья</b-button>

      <!-- Поиск -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
              id="search-input"
              v-model="selected.search_query"
              placeholder="Поиск"

          />
          <b-input-group-append
              class="cursor-pointer"
              is-text
          >
            <feather-icon
                icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <!-- Селектор для категорий      -->
      <v-select
          id="blog-category"
          v-model="selected.categories"
          multiple
          :options="blogSidebar.categories"
          placeholder="Выбранные категории"
          label="title"
      />

      <!-- Категории -->
      <CategoriesList @loadCategories="getCategories"
                      @sortByCategory="sortByCategory"
                      :categories="blogSidebar.categories">
      </CategoriesList>
    </div>

  </content-with-sidebar>
</template>

<script>
import {
  BOverlay,
  BButton,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import CategoriesList from "@/components/views/news/sidebar/CategoriesList";
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import vSelect from 'vue-select'
import api from "@/api";
import utils from "@/utils";

export default {
  name: "PostsList",
  components: {
    CategoriesList,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    ContentWithSidebar,
    vSelect,
  },

  mounted() {
    this.getCategories()
    this.getPosts()
  },
  methods: {
    getFullname(user){
      if (user.account_type === 'ADMIN')
        return 'Администратор'
      return user.fullName;

    },
    getPosts(query, category) {
      const self = this;
      this.dataLoading = true;
      api.posts
          .getPosts(query, category)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.blogList = data.result
              for (let post of this.blogList) {
                post.author.fullName = `${post.author.last_name} ${post.author.first_name} ${post.author.middle_name}`
                post.categoryTitle = []
                post.commentsLength = 0
                if (!post.picture){
                  post.picture = "/static/images/no_photo.png"
                }
                api.posts.
                getComments(post.id).
                then((response) => {
                  post.commentsLength = response.data.result.length
                  this.$forceUpdate()
                })
                for (let category in post.categories){
                  let id = (post.categories[category])
                  for (let title in this.blogSidebar.categories){
                    if (this.blogSidebar.categories[title].id === id){
                      post.categoryTitle.push(this.blogSidebar.categories[title].title)
                    }
                  }
                }
              }
              self.dataLoading = false;
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      // this.isAddNewUserSidebarActive = false;
    },
    getCategories() {
      const self = this;
      api.posts
          .getCategories()
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.blogSidebar.categories = data.result
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },

    sortByCategory(data){
      if(!this.selected.categories.includes(data.category)){
        this.selected.categories.push(data.category)
      }
    },
  },
  watch: {
    selected: {
      deep: true,
      handler(val){
        const self = this;
        let ids = []
        for (let category in val.categories){
          ids.push(val.categories[category].id)
        }
        this.dataLoading = true;
       if (ids.length>0){
         api.posts
             .getPosts(this.selected.search_query, ids)
             .then((response) => {
               const data = response.data;
               if (data.error) {
                 throw "Ошибка";
               } else {
                 this.blogList = data.result
                 for (let post of this.blogList) {
                   post.author.fullName = `${post.author.last_name} ${post.author.first_name} ${post.author.middle_name}`
                   post.categoryTitle = []
                   post.commentsLength = 0
                   if (!post.picture){
                     post.picture = "/static/images/no_photo.png"
                   }
                   api.posts.
                   getComments(post.id).
                   then((response) => {
                     post.commentsLength = response.data.result.length
                     this.$forceUpdate()
                   })
                   for (let category in post.categories){
                     let id = (post.categories[category])
                     for (let title in this.blogSidebar.categories){
                       if (this.blogSidebar.categories[title].id === id){
                         post.categoryTitle.push(this.blogSidebar.categories[title].title)
                       }
                     }
                   }
                 }
                 self.dataLoading = false;
               }
             })
             .catch((err) => {
               const data = err.response.data;
               if (data && data.error) {
                 utils.notificateError(self, data.message, data.errorCode);
               }
             });
       }
       else {
         api.posts
             .getPosts(this.selected.search_query)
             .then((response) => {
               const data = response.data;
               if (data.error) {
                 throw "Ошибка";
               } else {
                 this.blogList = data.result
                 for (let post of this.blogList) {
                   post.author.fullName = `${post.author.last_name} ${post.author.first_name} ${post.author.middle_name}`
                   post.categoryTitle = []
                   post.commentsLength = 0
                   if (!post.picture){
                     post.picture = "/static/images/no_photo.png"
                   }
                   api.posts.
                   getComments(post.id).
                   then((response) => {
                     post.commentsLength = response.data.result.length
                     this.$forceUpdate()
                   })
                   for (let category in post.categories){
                     let id = (post.categories[category])
                     for (let title in this.blogSidebar.categories){
                       if (this.blogSidebar.categories[title].id === id){
                         post.categoryTitle.push(this.blogSidebar.categories[title].title)
                       }
                     }
                   }
                 }
                 self.dataLoading = false;
               }
             })
             .catch((err) => {
               const data = err.response.data;
               if (data && data.error) {
                 utils.notificateError(self, data.message, data.errorCode);
               }
             });
       }
      },
    }
  },

  data() {
    return {
      dataLoading: true,
      selected: {
        categories: [],
        search_query: '',
      },
      blogList: [],
      sortCategory: '',
      blogSidebar: {
        categories: [],
      },
      imgProp: {
        height: 390,
      },
    }
  },
}
</script>

<style scoped>
.blog-content-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.article{
  height: 510px;
  overflow: hidden;
}

.card-img-top{
  overflow: hidden;
  height: 250px;
  object-fit: cover;
}

.card-buttons{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


</style>
